import noUiSlider from 'nouislider'

function Trim() {
  /* eslint-disable */
  // Get a handle to the video
  const video = document.getElementById('videoScreen')
  const videoCam = document.getElementById('videoCam')
  const blobDaCam = document.getElementById('blobDaCam')
  const btnPlayPause = document.getElementById('btnPlayPause')
  const btnStop = document.getElementById('btnStop')
  //btnMute      = document.getElementById('btnMute');
  const progressBar = document.getElementById('progress-bar')
  //volumeBar    = document.getElementById('volume-bar');
  /*
volumeBar.addEventListener("change", function(evt) {
    video.volume = evt.target.value;
    blobDaCam.volume = evt.target.value;
});
*/
  //document.getElementById('btnFullScreen').disabled = true;

  // Add a listener for the timeupdate event so we can update the progress bar
  video.addEventListener('timeupdate', updateProgressBar, false)

  // Add a listener for the play and pause events so the buttons state can be updated
  video.addEventListener(
    'play',
    function () {
      // Change the button to be a pause button
      changeButtonType(
        btnPlayPause,
        '<i class="fa-regular fa-circle-pause"></i>',
        'btn btn-info'
      )
    },
    false
  )
  videoCam.addEventListener(
    'play',
    function () {
      // Change the button to be a pause button
      changeButtonType(
        btnPlayPause,
        '<i class="fa-regular fa-circle-pause"></i>',
        'btn btn-info'
      )
    },
    false
  )

  video.addEventListener(
    'pause',
    function () {
      // Change the button to be a play button
      changeButtonType(
        btnPlayPause,
        '<i class="fa-regular fa-circle-play"></i>',
        'btn btn-info'
      )
    },
    false
  )
  videoCam.addEventListener(
    'pause',
    function () {
      // Change the button to be a play button
      changeButtonType(
        btnPlayPause,
        '<i class="fa-regular fa-circle-play"></i>',
        'btn btn-info'
      )
    },
    false
  )

  /*
video.addEventListener('volumechange', function(e) { 
    // Update the button to be mute/unmute
    if (video.muted) changeButtonType(btnMute, 'unmute');
    else changeButtonType(btnMute, 'mute');
}, false); 
*/

  video.addEventListener(
    'ended',
    function () {
      this.pause()
    },
    false
  )

  videoCam.addEventListener(
    'ended',
    function () {
      this.pause()
    },
    false
  )
  ////////////////////////

  progressBar.addEventListener('click', seek)

  function seek(e) {
    var percent = e.offsetX / this.offsetWidth
    video.currentTime = percent * video.duration

    e.target.value = Math.floor(percent / 100)
    e.target.innerHTML = progressBar.value + '% played'
  }

  //btnPlayPause.addEventListener('click', playPauseVideo)

  // function playPauseVideo(start = arrayTimes[0], end = arrayTimes[1]) {
  //   if (video.paused || video.ended) {
  //     // Change the button to a pause button
  //     changeButtonType(btnPlayPause, 'pause')

  //     // start at position
  //     video.currentTime = start
  //     // stop at position
  //     video.addEventListener('timeupdate', function () {
  //       if (this.currentTime >= end) {
  //         this.pause()
  //       }
  //     })

  //     // start at position
  //     videoCam.currentTime = start
  //     // stop at position
  //     videoCam.addEventListener('timeupdate', function () {
  //       if (this.currentTime >= end) {
  //         this.pause()
  //       }
  //     })
  //     video.play()
  //     videoCam.play()
  //   } else {
  //     // Change the button to a play button
  //     changeButtonType(btnPlayPause, 'play')
  //     video.pause()
  //     videoCam.pause()
  //   }
  // }

  // Stop the current media from playing, and return it to the start position
  // function stopVideo() {
  //   video.pause()

  //   if (video.currentTime) {
  //     video.currentTime = 0
  //   }
  // }

  //btnStop.addEventListener('click', stopVideo)

  // Toggles the media video's mute and unmute status
  /*function muteVolume() {
if (video.muted) {
    // Change the button to a mute button
    changeButtonType(btnMute, 'mute');
    video.muted = false;
    
}
else {
    // Change the button to an unmute button
    changeButtonType(btnMute, 'unmute');
    video.muted = true;
    
}
}*/

  // Replays the media currently loaded in the video
  function replayVideo() {
    resetvideo()
    video.play()
  }

  // Update the progress bar
  function updateProgressBar() {
    // Work out how much of the media has played via the duration and currentTime parameters
    var percentage = Math.floor((100 / video.duration) * video.currentTime)
    // Update the progress bar's value
    progressBar.value = percentage
    // Update the progress bar's text (for browsers that don't support the progress element)
    progressBar.innerHTML = percentage + '% played'
  }

  // Updates a button's title, innerHTML and CSS class
  function changeButtonType(btn, value, classname) {
    //btn.title     = value;
    btn.innerHTML = value
    btn.className = classname
  }

  function resetvideo() {
    progressBar.value = 0
    // Move the media back to the start
    video.currentTime = 0

    // Set the play/pause button to 'play'
    changeButtonType(btnPlayPause, 'play')
  }

  function exitFullScreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen()
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen()
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen()
    }
  }

  function toggleFullScreen(elem) {
    elem = elem || document.documentElement

    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen()
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen()
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen()
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      }
    }
  }
}

export default Trim
